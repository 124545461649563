import { ChainId } from 'spaceswap-sdk-zksync-test'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.EVMOSTEST]: '0x92E8DDEeA77cd2c741eCB319ED8694724C63D275',
  [ChainId.EVMOS]: '0x9e6A96Ace5185eE381A3A75f596817Db07062eB3',
  [ChainId.CELO]: '0x41e94014226AE5877aE99e99AbF48548E17Df173',
  [ChainId.CELOALFAJORES]: '0x75f59534dd892c1f8a7b172d639fa854d529ada3',
  [ChainId.ZKSYNCTEST]: '0x75C13af8944a340A05702b568BD9e266f2FE709a',
  [ChainId.ZKSYNC]: '0x047560E3a91755c95e947FD7AAa3FBc39cF13351',
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
