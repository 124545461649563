import {Price} from './fractions/price'
import {TokenAmount} from './fractions/tokenAmount'
import invariant from 'tiny-invariant'
import JSBI from 'jsbi'
 import {keccak256, pack} from '@ethersproject/solidity'
 import {getCreate2Address} from '@ethersproject/address'

import {
    _1000,
    _997,
    BigintIsh,
    ChainId,
    FACTORY_ADDRESS,
    FIVE,
    INIT_CODE_HASH,
    MINIMUM_LIQUIDITY,
    ONE,
    ZERO
} from '../constants'
import {parseBigintIsh, sqrt} from '../utils'
import {InsufficientInputAmountError, InsufficientReservesError} from '../errors'
import {Token} from './token'

let PAIR_ADDRESS_CACHE: { [token0Address: string]: { [token1Address: string]: string } } = {}

export class Pair {
  public readonly liquidityToken: Token
  private readonly tokenAmounts: [TokenAmount, TokenAmount]

  public static getAddress(tokenA: Token, tokenB: Token): string {
    const tokens = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks
    // //TK0-N
    // if((tokens[0].address == '0x4524C9135F64fd3981C159e78B7761aDD804F727' && tokens[1].address == '0xD3765838f9600Ccff3d01EFA83496599E0984BD2') || (tokens[1].address == '0x4524C9135F64fd3981C159e78B7761aDD804F727' && tokens[0].address == '0xD3765838f9600Ccff3d01EFA83496599E0984BD2')){
    //   return "0x8263e092CaC9946BD5D2b23FE9f24aCcA32cb0a2";
    // }
	const SPACE = '0x880F03cA84e6Cf0D0871c9818A2981DEBabA22b3';
	const WETH = '0x8a144308792a23AadB118286aC0dec646f638908';
	const USDC = '0x0faF6df7054946141266420b43783387A78d82A9';
	const wBTC = '0x0BfcE1D53451B4a8175DD94e6e029F7d8a701e9c';
	const LINK = '0x40609141Db628BeEE3BfAB8034Fc2D8278D0Cc78';
	const DAI = '0x3e7676937A7E96CFB7616f255b9AD9FF47363D4b';
	var SPACE_USDC = '0xB264B224609a58777F48172f6863b1E58521a91D';
//	    SPACE_USDC = '0xC9978eac77695b0e6208491256Cebfe50e338A13';
	var SPACE_ETH = '0x04CA71B4FB4012546D64C1e820D4BD45Afb41BAc';
	    SPACE_ETH = '0xc0B7C869BA924c05F64333D9CAa21F4424eb4B30';
	var SPACE_DAI = '0x02B20ffA0025ff0276CbA1F7Bda4d3060809Ddbc';
	var SPACE_BTC = '0x4152c37608F63695c9af902EC457BCb1D0Dcc405';
	var SPACE_LINK = '0x4667bC749ED765d39E367D8DB48E05a5007Bd43E';
	var ETH_USDC = '0x39Ac0f01488C177ca5677e3F33a32af24137d570';
	    ETH_USDC = '0xB2bAbfBBB09A17Fb38cBc7E793B18078dd241347';
	var wBTC_USDC = '0xE5e3aE317095f3857cF9Ba180c28A7dcB58CEb3B';
	var LINK_USDC = '0x3f7f346a275EC0Efd23c9a1aE02Cc6953358c880';
	var DAI_USDC = '0xc3DE208a7fc612A4ca9190DF543048b0FA30967b';
	var LINK_DAI = '0x165eC23cb73aEDd719E2e7c1334e7C40288A3B74';
	var LINK_BTC = '0xB309d362f8D7E485bb9887F618eF2B7C8eE116cb';
	
    //STAR-USDC
    if((tokens[0].address == SPACE && tokens[1].address == USDC) || (tokens[1].address == SPACE && tokens[0].address == USDC)){
      return SPACE_USDC;
    }
    // //STAR-ETH
    if((tokens[0].address == SPACE && tokens[1].address == WETH) || (tokens[1].address == SPACE && tokens[0].address == WETH)){
      return SPACE_ETH;
    }
    //STAR-DAI
    if((tokens[0].address == SPACE && tokens[1].address == DAI) || (tokens[1].address == SPACE && tokens[0].address == DAI)){
      return SPACE_DAI;
    }
    //STAR—BTC
    if((tokens[0].address == SPACE && tokens[1].address == wBTC) || (tokens[1].address == SPACE && tokens[0].address == wBTC)){
      return SPACE_BTC;
    }
    //STAR—LINK
    if((tokens[0].address == SPACE && tokens[1].address == LINK) || (tokens[1].address == SPACE && tokens[0].address == LINK)){
      return SPACE_LINK;
    }
    //ETH-USDC
    if((tokens[0].address == USDC && tokens[1].address == WETH) || (tokens[1].address == USDC && tokens[0].address == WETH)){
      return ETH_USDC;
    }

    //wBTC-USDC
    if((tokens[0].address == USDC && tokens[1].address == wBTC) || (tokens[1].address == USDC && tokens[0].address == wBTC)){
      return wBTC_USDC;
    }

    //LINK-USDC
    if((tokens[0].address == USDC && tokens[1].address == LINK) || (tokens[1].address == USDC && tokens[0].address == LINK)){
      return LINK_USDC;
    }

    //DAI-USDC
    if((tokens[0].address == USDC && tokens[1].address == DAI) || (tokens[1].address == USDC && tokens[0].address == DAI)){
      return DAI_USDC;
    }

    //LINK-DAI
    if((tokens[0].address == LINK && tokens[1].address == DAI) || (tokens[1].address == LINK && tokens[0].address == DAI)){
      return LINK_DAI;
    }

    //LINK-BTC
    if((tokens[0].address == LINK && tokens[1].address == wBTC) || (tokens[1].address == LINK && tokens[0].address == wBTC)){
      return LINK_BTC;
    }


    if (PAIR_ADDRESS_CACHE?.[tokens[0].address]?.[tokens[1].address] === undefined) {
      PAIR_ADDRESS_CACHE = {
        ...PAIR_ADDRESS_CACHE,
        [tokens[0].address]: {
          ...PAIR_ADDRESS_CACHE?.[tokens[0].address],
          [tokens[1].address]: getCreate2Address(
              FACTORY_ADDRESS,
              keccak256(['bytes'], [pack(['address', 'address'], [tokens[0].address, tokens[1].address])]),
              INIT_CODE_HASH
          )
        }
      }
    }
    // console.log(11111111111111111111);
    // console.log(PAIR_ADDRESS_CACHE[tokens[0].address][tokens[1].address])

    return PAIR_ADDRESS_CACHE[tokens[0].address][tokens[1].address]



  }

  public constructor(tokenAmountA: TokenAmount, tokenAmountB: TokenAmount) {
    const tokenAmounts = tokenAmountA.token.sortsBefore(tokenAmountB.token) // does safety checks
      ? [tokenAmountA, tokenAmountB]
      : [tokenAmountB, tokenAmountA]
    this.liquidityToken = new Token(
      tokenAmounts[0].token.chainId,
      Pair.getAddress(tokenAmounts[0].token, tokenAmounts[1].token),
      18,
      'SLP-V2',
      'SpaceSwap V2'
    )
    this.tokenAmounts = tokenAmounts as [TokenAmount, TokenAmount]
  }

  /**
   * Returns true if the token is either token0 or token1
   * @param token to check
   */
  public involvesToken(token: Token): boolean {
    return token.equals(this.token0) || token.equals(this.token1)
  }

  /**
   * Returns the current mid price of the pair in terms of token0, i.e. the ratio of reserve1 to reserve0
   */
  public get token0Price(): Price {
    return new Price(this.token0, this.token1, this.tokenAmounts[0].raw, this.tokenAmounts[1].raw)
  }

  /**
   * Returns the current mid price of the pair in terms of token1, i.e. the ratio of reserve0 to reserve1
   */
  public get token1Price(): Price {
    return new Price(this.token1, this.token0, this.tokenAmounts[1].raw, this.tokenAmounts[0].raw)
  }

  /**
   * Return the price of the given token in terms of the other token in the pair.
   * @param token token to return price of
   */
  public priceOf(token: Token): Price {
    invariant(this.involvesToken(token), 'TOKEN')
    return token.equals(this.token0) ? this.token0Price : this.token1Price
  }

  /**
   * Returns the chain ID of the tokens in the pair.
   */
  public get chainId(): ChainId {
    return this.token0.chainId
  }

  public get token0(): Token {
    return this.tokenAmounts[0].token
  }

  public get token1(): Token {
    return this.tokenAmounts[1].token
  }

  public get reserve0(): TokenAmount {
    return this.tokenAmounts[0]
  }

  public get reserve1(): TokenAmount {
    return this.tokenAmounts[1]
  }

  public reserveOf(token: Token): TokenAmount {
    invariant(this.involvesToken(token), 'TOKEN')
    return token.equals(this.token0) ? this.reserve0 : this.reserve1
  }

  public getOutputAmount(inputAmount: TokenAmount): [TokenAmount, Pair] {
    invariant(this.involvesToken(inputAmount.token), 'TOKEN')
    if (JSBI.equal(this.reserve0.raw, ZERO) || JSBI.equal(this.reserve1.raw, ZERO)) {
      throw new InsufficientReservesError()
    }
    const inputReserve = this.reserveOf(inputAmount.token)
    const outputReserve = this.reserveOf(inputAmount.token.equals(this.token0) ? this.token1 : this.token0)
    const inputAmountWithFee = JSBI.multiply(inputAmount.raw, _997)
    const numerator = JSBI.multiply(inputAmountWithFee, outputReserve.raw)
    const denominator = JSBI.add(JSBI.multiply(inputReserve.raw, _1000), inputAmountWithFee)
    const outputAmount = new TokenAmount(
      inputAmount.token.equals(this.token0) ? this.token1 : this.token0,
      JSBI.divide(numerator, denominator)
    )
    if (JSBI.equal(outputAmount.raw, ZERO)) {
      throw new InsufficientInputAmountError()
    }
    return [outputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))]
  }

  public getInputAmount(outputAmount: TokenAmount): [TokenAmount, Pair] {
    invariant(this.involvesToken(outputAmount.token), 'TOKEN')
    if (
      JSBI.equal(this.reserve0.raw, ZERO) ||
      JSBI.equal(this.reserve1.raw, ZERO) ||
      JSBI.greaterThanOrEqual(outputAmount.raw, this.reserveOf(outputAmount.token).raw)
    ) {
      throw new InsufficientReservesError()
    }

    const outputReserve = this.reserveOf(outputAmount.token)
    const inputReserve = this.reserveOf(outputAmount.token.equals(this.token0) ? this.token1 : this.token0)
    const numerator = JSBI.multiply(JSBI.multiply(inputReserve.raw, outputAmount.raw), _1000)
    const denominator = JSBI.multiply(JSBI.subtract(outputReserve.raw, outputAmount.raw), _997)
    const inputAmount = new TokenAmount(
      outputAmount.token.equals(this.token0) ? this.token1 : this.token0,
      JSBI.add(JSBI.divide(numerator, denominator), ONE)
    )
    return [inputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))]
  }

  public getLiquidityMinted(
    totalSupply: TokenAmount,
    tokenAmountA: TokenAmount,
    tokenAmountB: TokenAmount
  ): TokenAmount {
    invariant(totalSupply.token.equals(this.liquidityToken), 'LIQUIDITY')
    const tokenAmounts = tokenAmountA.token.sortsBefore(tokenAmountB.token) // does safety checks
      ? [tokenAmountA, tokenAmountB]
      : [tokenAmountB, tokenAmountA]
    invariant(tokenAmounts[0].token.equals(this.token0) && tokenAmounts[1].token.equals(this.token1), 'TOKEN')

    let liquidity: JSBI
    if (JSBI.equal(totalSupply.raw, ZERO)) {
      liquidity = JSBI.subtract(sqrt(JSBI.multiply(tokenAmounts[0].raw, tokenAmounts[1].raw)), MINIMUM_LIQUIDITY)
    } else {
      const amount0 = JSBI.divide(JSBI.multiply(tokenAmounts[0].raw, totalSupply.raw), this.reserve0.raw)
      const amount1 = JSBI.divide(JSBI.multiply(tokenAmounts[1].raw, totalSupply.raw), this.reserve1.raw)
      liquidity = JSBI.lessThanOrEqual(amount0, amount1) ? amount0 : amount1
    }
    if (!JSBI.greaterThan(liquidity, ZERO)) {
      throw new InsufficientInputAmountError()
    }
    return new TokenAmount(this.liquidityToken, liquidity)
  }

  public getLiquidityValue(
    token: Token,
    totalSupply: TokenAmount,
    liquidity: TokenAmount,
    feeOn: boolean = false,
    kLast?: BigintIsh
  ): TokenAmount {
    invariant(this.involvesToken(token), 'TOKEN')
    invariant(totalSupply.token.equals(this.liquidityToken), 'TOTAL_SUPPLY')
    invariant(liquidity.token.equals(this.liquidityToken), 'LIQUIDITY')
    invariant(JSBI.lessThanOrEqual(liquidity.raw, totalSupply.raw), 'LIQUIDITY')

    let totalSupplyAdjusted: TokenAmount
    if (!feeOn) {
      totalSupplyAdjusted = totalSupply
    } else {
      invariant(!!kLast, 'K_LAST')
      const kLastParsed = parseBigintIsh(kLast)
      if (!JSBI.equal(kLastParsed, ZERO)) {
        const rootK = sqrt(JSBI.multiply(this.reserve0.raw, this.reserve1.raw))
        const rootKLast = sqrt(kLastParsed)
        if (JSBI.greaterThan(rootK, rootKLast)) {
          const numerator = JSBI.multiply(totalSupply.raw, JSBI.subtract(rootK, rootKLast))
          const denominator = JSBI.add(JSBI.multiply(rootK, FIVE), rootKLast)
          const feeLiquidity = JSBI.divide(numerator, denominator)
          totalSupplyAdjusted = totalSupply.add(new TokenAmount(this.liquidityToken, feeLiquidity))
        } else {
          totalSupplyAdjusted = totalSupply
        }
      } else {
        totalSupplyAdjusted = totalSupply
      }
    }

    return new TokenAmount(
      token,
      JSBI.divide(JSBI.multiply(liquidity.raw, this.reserveOf(token).raw), totalSupplyAdjusted.raw)
    )
  }
}
